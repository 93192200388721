<template>
  <div>
    <section class="hero has-background-white-bis">
        <div class="hero-body">
          <div class="container">
            <h1 class="is-size-1-desktop">
              التحكم
            </h1>
          </div>
        </div>
        <div class="hero-footer">
          <div class="container">
            <div class="tabs is-boxed">
              <ul>
                <router-link tag="li" active-class="is-active" :to="{ path: '/control/' }" exact v-if="getAdmins"><a>إعدادات</a></router-link>
                <router-link tag="li" active-class="is-active" :to="{ path: '/control/profile' }"><a>الحساب</a></router-link>
                <router-link tag="li" active-class="is-active" :to="{ path: '/control/users' }" v-if="getRoot"><a>الأعضاء</a></router-link>
                <router-link tag="li" active-class="is-active" :to="{ path: '/control/categories' }" v-if="getAdmins"><a>الأقسام</a></router-link>
                <router-link tag="li" active-class="is-active" :to="{ path: '/control/tags' }" v-if="getAdmins"><a>التبويب</a></router-link>
              </ul>
            </div>
          </div>
        </div>
    </section>
    <section class="section">
      <div class="container">
        <router-view/>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  created () {
    this.$store.dispatch('prepRoot')
    this.$store.dispatch('prepAdmins')
  },
  computed: {
    ...mapGetters([
      'getRoot',
      'getAdmins'
    ])
  }
}
</script>
